import ApiService from './ApiService'
import {useQuery} from '@tanstack/react-query'

export const apiSignIn = async ({email, password}) =>
  await ApiService.fetchData({
    url: `/auth/local`,
    method: 'post',
    data: {
      identifier: email,
      password
    }
  })


export const apiSignUp = async ({username, email, password, first_name, last_name}) =>
  await ApiService.fetchData({
    url: `/auth/local/register`,
    method: 'post',
    data: {
      username, email, password,
      first_name, last_name
    }
  })

export const apiConfirmation = ({confirmation}, options) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(
    ['email-confirmation', confirmation],
    async () => await ApiService.fetchData({
      url: `/auth/email-confirmation`,
      params: {
        confirmation
      }
    }),
    options
  )
}


export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: '/sign-out',
    method: 'post',
    data,
  })
}

export async function apiForgotPassword({email}) {
  return ApiService.fetchData({
    url: '/auth/forgot-password',
    method: 'post',
    data: {email},
  })
}

export async function apiResetPassword({code, password, confirmPassword}) {
  return ApiService.fetchData({
    url: '/auth/reset-password',
    method: 'post',
    data: {
      code,
      password,
      passwordConfirmation: confirmPassword
    },
  })
}
