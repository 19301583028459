import alertToast from '../components/shared/AlertToast'
import {QueryClient} from "@tanstack/react-query";

const defaultResponse = {
  onError: (error, variables, context) => {
    const message = error?.response?.data?.error?.message ?? error?.message
    alertToast({
      message,
      type: 'danger',
      duration: 5000
    })
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...defaultResponse,
      refetchOnWindowFocus: false,
      retry: false
    },
    mutations: {
      ...defaultResponse
    }
  }
})

export const useGetFetchQuery = (name) => {
  return queryClient.getQueryData(name)
}
